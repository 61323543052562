<template>
    <div id="login" class="container-fluid flex-center" style="flex-direction: column; align-content: space-between;">
        <section class="login mt-auto mb-auto">
            
            <section class="login__content bg-white px-3 py-4 mb-3 mx-3 mx-md-0">

                <div class="login__logo d-flex justify-content-center align-items-center mb-3 my-5">
                    <div class="bg-success-light"
                        style="max-width: 200px; margin-left: 15px; border-radius: 50%;">
                        <img width="100px" class="m-3"
                            :src="img_logo">    
                    </div>
                </div>
                
                <div style="max-width: 460px;">
                    <div class="row">
                        <div class="col-12 mb-2 py-3">
                            <input 
                                v-model="email" id="input_email"
                                placeholder="Ingrese su correo" 
                                type="email" 
                                name="email" 
                                class="full-width custom_input">
                            <span class="text-danger" v-if="errors.email">
                                {{ errors.email }}
                            </span>
                        </div>

                        <div class="col-12 py-3 mb-4 ">

                            <input v-model="password" id="input_password"
                                @keydown.enter="login"
                                placeholder="Contraseña" 
                                :type="local_type" 
                                name="password" 
                                class="full-width custom_input">

                            <!-- <img :src="(local_type == 'password') ? icon_eye : icon_eye_slash" 
                                @click="showPassword()" 
                                class="cursor-pointer"
                                :style="`${(local_type == 'password') ? 'margin-left: -30px; width: 18px;' : 'margin-left: -31px; width: 20px;' }`"> -->
                            
                            <span class="text-danger" v-if="errors.password">
                                {{ errors.password}}
                            </span>
                        </div>

                        <div class="col-12 mb-5">
                            <button @click.stop="login" 
                                class="btn btn-success text-white btn-pill full-width mb-5"
                                :disabled="is_login_process">
                                Ingresar
                            </button>
                        </div>


                    </div>
                </div>
            </section>
        </section>

        <section class="mb-3">
            <span class="text-gray">
                2023. Mono Cilindrero ® Todos los derechos reservados.
            </span>
        </section>
    </div>
</template>

<script>
    // Store
    import { mapGetters } from 'vuex'

    // COMPONENTS
    
    // VIDEOS
    
    // IMAGES
    import img_logo from 'ASSETS/images/natura_logo.png'
    import icon_eye from 'ASSETS/icons/eye-icon'
    import icon_eye_slash from 'ASSETS/icons/eye-slash-icon'

    export default {
        name: 'login',
        components: {

        },
        data() {
            return {
                img_logo,
                icon_eye,
                icon_eye_slash,
                is_login_process: false,
                username: null,
                password: null,
                email: null,
                //password: 'emi240406'
                local_type: 'password',
                errors: {
                    login: null,
                    password: null,
                    email: null
                }
            }
        },
        computed: {
            ...mapGetters([
                'getSession',
                'getLogin'
            ]),

            current_route_name() {
                return this.$route.name
            },
            
        },
        methods: {
            showPassword() {
                this.local_type = (this.local_type === 'password') ? 'text' : 'password'
            },
            redirect(name_route) {
                if(this.$route.name !== name_route) {
                    window.scrollTo(0, 0)

                    this.$router.push({
                        name: name_route
                    })
                }
            },
            async login() {

                for(let error in this.errors) {
                    
                    this.errors[error] = null
                }

                let complete = true

                if(_.isEmpty(this.email) || _.isNull(this.email)) {
                    this.errors['email'] = '*Campo obligatorio'
                    complete = false
                }

                if(_.isEmpty(this.password) || _.isNull(this.password)) {
                    this.errors['password'] = '*Campo obligatorio'
                    complete = false
                }

                if(complete) {
                    this.is_login_process = true;

                    let payload = {
                        email: this.email,
                        password: this.password
                    }

                    await this.$store.dispatch('login', payload)

                    if(this.getLogin) {
                        this.redirect('articles');
                    }

                    else {
                        this.is_login_process = false
                    }

                }
                
            }
        },
        created() {
            
        }
    }
</script>

<style lang="scss">
    #login {
        min-height: 93vh;
        background: white;
        

        .login {

            &__logo {

            }

            &__content {
                border-radius: 12px;
                filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.22));

                .custom_input {
                    border: none;
                    border-block-end: 2px solid #65AD6A;
                    -webkit-text-fill-color: black;
                    &::placeholder{
                        color: #D5D9E7;
                        font-size: 18px;
                    }
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
    }
</style>